import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { god_indicator15Min } from '~/trades/indicators/god/god_indicator15Min'
import { god_indicator1Min } from '~/trades/indicators/god/god_indicator1Min'
import { god_indicator5Min } from '~/trades/indicators/god/god_indicator5Min'
import { god_movAvg } from '~/trades/indicators/god/god_movAvg'
import { god_dayTrade } from '~/trades/indicators/god/god_dayTrade'
import { god_volume } from '~/trades/indicators/god/god_volume'
import { store } from '../heineken_template/_private/store'
import { god_long } from '~/trades/indicators/god/god_long'
import { god_short } from '~/trades/indicators/god/god_short'

const DEFAULT_SYMBOL = 'TX-1'

export const god_dayTradeIndicators = [
  god_dayTrade,
  god_movAvg,
  god_volume,
  god_indicator1Min,
  god_indicator5Min,
  god_indicator15Min,
]

export const god_longIndicators = [
  god_long,
  god_movAvg,
  god_volume,
  god_indicator1Min,
  god_indicator5Min,
  god_indicator15Min,
]

export const god_shortIndicators = [
  god_short,
  god_movAvg,
  god_volume,
  god_indicator1Min,
  god_indicator5Min,
  god_indicator15Min,
]

export const god_strategyGroup = {
  main: [
    {
      displayName: '當沖策略',
      indicators: [...god_dayTradeIndicators],
      symbol: DEFAULT_SYMBOL,
      interval: '1',
      panesRatio: 60,
    },
    {
      displayName: '波段策略(多)',
      indicators: [...god_longIndicators],
      symbol: DEFAULT_SYMBOL,
      interval: '1',
      panesRatio: 60,
    },
    {
      displayName: '波段策略(空)',
      indicators: [...god_shortIndicators],
      symbol: DEFAULT_SYMBOL,
      interval: '1',
      panesRatio: 60,
    },
  ] as ChartTypes.StrategyConfig[],
}

export const god_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...god_dayTradeIndicators,
    ...god_longIndicators,
    ...god_shortIndicators,
  ]

  store.charting.initStrategy({
    configs: [...god_strategyGroup.main],
  })

  store.charting.changeSymbol(DEFAULT_SYMBOL)
}
