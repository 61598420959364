import { memo } from 'react'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  fill_vertical_all_center,
} from '~/modules/AppLayout/FlexGridCss'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import QuoteValueItem from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

/** 可以掛載於TopBar的報價跑馬燈 */
const MarqueeSymbolBody = memo<ReactProps<{ quote?: Signalr.ValueOfOHLC }>>(function SymbolMarquee(
  props,
) {
  const quote = props.quote

  return (
    <classes.Root css={baseStyled}>
      <classes.container>
        <classes.item
          justify='center'
          column={1}
          row={1}
        >
          <QuoteValueItem.arrow.Display
            quote={quote}
            changeType={'closeChange'}
          />
          <SymbolName symbol={quote?.symbol} />
          {!quote?.symbol.includes('-') && quote?.symbol}
        </classes.item>
        <classes.item
          justify='start'
          column={2}
          row={1}
        >
          <QuoteValueItem.change.Display
            quote={quote}
            changeType={'closeChange'}
          />
        </classes.item>
        <classes.item
          justify='center'
          column={1}
          row={2}
          css={css`
            font-size: 16px;
          `}
        >
          <QuoteValueItem.ohlc.Display
            quote={quote}
            ohlcType='close'
          />
        </classes.item>
        <classes.item
          justify='start'
          column={2}
          row={2}
        >
          <QuoteValueItem.change.Display
            quote={quote}
            changeType={'closeChangePercent'}
            unit={'%'}
          />
        </classes.item>
      </classes.container>
    </classes.Root>
  )
})

const classes = {
  Root: styled.div`
    width: 100%;
    height: 100%;
  `,
  container: styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 50% 50%;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
  `,
  item: styled.div<{ column: number; row: number; justify: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.justify};
    margin: 0px;
    grid-column: ${props => props.column};
    grid-row: ${props => props.row};
  `,
}

const baseStyled = css`
  ${classes.Root} {
  }
  ${classes.container} {
    background-color: #1d1e29;
    &:hover {
      background-color: #2d2e39;
    }
  }
  ${classes.item} {
  }
`

export default {
  Display: MarqueeSymbolBody,
  classes,
}
