import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useEffect, useState } from 'react'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { getIndicatorAngular } from '~/modules/strength-state/getIndicatorAngular'
import { PriceGauges } from './component/PriceGauges'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { proxy, useSnapshot } from 'valtio'
import { store } from '~/pages/heineken_template/_private/store'
import IntradayTrendChart from '~/modules/trendChart'
import { SegmentedControl } from '@mantine/core'
import DarkGauge from './component/DarkGauge'
import { SimpleQuoteListInteractWithChart, WatchListWithChart } from '~/modules/symbolQuote/simple'
import { FuiTabs2 } from '../heineken_template/components/FuiTabs2'
import { TrendScore } from './component/TrendScore'

export const asia71178Store = proxy({
  tabs1: new FuiTabs2(['default', 'watchList']),
})

const watchListGroup = 'asia71178_group_1'

export const Asia71178_SidePane = memo<ReactProps>(function Asia71178_SidePane() {
  const [number, setNumber] = useState(Math.random())

  const charting = useSnapshot(store.charting)
  const symbolNumber = charting.symbol

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNumber(Math.random())
    }, 2000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const marketValue = getIndicatorAngular(number * 1000) * 100

  return (
    <styleds.container>
      {/* <PriceGauges currentValue={marketValue} /> */}
      <styleds.title>主力買盤</styleds.title>
      <styleds.componentCard height={136}>
        <DarkGauge.Display
          currentValue={marketValue}
          fillType='default'
        >
          主力買盤
        </DarkGauge.Display>
      </styleds.componentCard>
      <styleds.title>趨勢得分</styleds.title>

      <styleds.componentCard height={80}>
        <TrendScore />
      </styleds.componentCard>

      <styleds.componentCard height={164}>
        <TitleSymbolQuote.Default symbol={symbolNumber} />
        <div
          css={css`
            height: 134px;
          `}
        >
          <IntradayTrendChart
            symbol={symbolNumber}
            ticksSize={14}
            ticksHeight={20}
            priceTicksMargin={2.5}
            priceTicksSize={14}
          />
        </div>
      </styleds.componentCard>

      <SegmentedControl
        css={css`
          width: 100%;
          &.mantine-1wvsdi7 {
            background-color: #d3d3d3 !important;
          }
        `}
        radius={4}
        onChange={val => {
          asia71178Store.tabs1.store.active = val as typeof asia71178Store.tabs1.store.active
        }}
        data={[
          { label: '選股', value: 'default' },
          { label: '自選股', value: 'watchList' },
        ]}
      />
      <styleds.symbolList>
        <asia71178Store.tabs1.WithContent for='default'>
          <SimpleQuoteListInteractWithChart
            data={['2330', '2609', '8069', '1905', '2002']}
            chart={store.charting}
            handleClick={() => {
              null
            }}
          />
        </asia71178Store.tabs1.WithContent>
        <asia71178Store.tabs1.WithContent for='watchList'>
          <WatchListWithChart
            groupName={watchListGroup}
            useChart={store.charting}
            showStockFutures={false}
          />
        </asia71178Store.tabs1.WithContent>
      </styleds.symbolList>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    padding: 8px;
    gap: 4px;
    border-left: 1px solid #cccccc;
  `,
  componentCard: styled.div<{ height: number }>`
    width: 100%;
    height: ${props => props.height}px;
    padding: 4px;
    border-radius: 6px;
    box-shadow: 0px 1px 5px 1px #cccccc;
  `,
  title: styled.div`
    ${fill_vertical_all_center};
    height: 26px;
    border-radius: 3px;
    background-color: #e4e3e3;
  `,
  symbolList: styled.div`
    width: 100%;
    height: calc(100% - 500px);
    border-radius: 6px;
    box-shadow: 0px 1px 5px 1px #cccccc;
    padding: 4px;
  `,
}
