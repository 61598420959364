import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
} from '~/modules/AppLayout/FlexGridCss'

export const TrendScore = memo<ReactProps>(function TrendScore() {
  return (
    <div
      css={css`
        ${fill_horizontal_all_center};
        width: 100%;
        padding: 4px;
        gap: 4px;
      `}
    >
      <ScoreItem
        date={'4/20'}
        score={4}
      />
      <ScoreItem
        date={'4/21'}
        score={5}
      />
      <ScoreItem
        date={'4/24'}
        score={3}
      />
      <ScoreItem
        date={'4/25'}
        score={2}
      />
      <ScoreItem
        date={'4/26'}
        score={5}
      />
      <ScoreItem
        date={'4/27'}
        score={5}
      />
    </div>
  )
})

const ScoreItem = memo<ReactProps<{ date: string; score: number }>>(function ScoreItem(props) {
  const scoreFill = () => {
    if (props.score === 5) {
      return '#e45751'
    } else if (props.score === 4) {
      return '#e5a2a0'
    } else {
      return '#bbbbbb'
    }
  }
  return (
    <styleds.itemContainer>
      <styleds.dateContent>{props.date}</styleds.dateContent>
      <styleds.valueContent fill={scoreFill()}>{props.score}</styleds.valueContent>
    </styleds.itemContainer>
  )
})

const styleds = {
  itemContainer: styled.div`
    ${fill_vertical_all_center};
    cursor: pointer;
    &:hover {
      background-color: #f4f4f4;
      border-radius: 4px;
    }
  `,
  dateContent: styled.span`
    color: #353535;
    font-size: 14px;
  `,
  valueContent: styled.span<{ fill: string }>`
    ${fill_vertical_all_center};
    background-color: ${props => props.fill};
    border-radius: 4px;
    border: 1px solid #cccccc;
    color: #ffffff;
    font-weight: bold;
    border-radius: 5px;
    height: 30px;
    width: 30px;
  `,
}
