import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { daddy960_revive_cci } from '~/trades/indicators/daddy960_revive/daddy960_revive_cci'
import { daddy960_revive_macd } from '~/trades/indicators/daddy960_revive/daddy960_revive_macd'
import { store } from '../heineken_template/_private/store'

const DEFAULT_SYMBOL = 'TXAM-1'

const reviveIndicators = [daddy960_revive_macd, daddy960_revive_cci]

export const daddy960_revive_strategyGroup = {
  main: [
    {
      displayName: 'revive',
      indicators: [...reviveIndicators],
      symbol: DEFAULT_SYMBOL,
      interval: 'D',
      panesRatio: 60,
    },
  ] as ChartTypes.StrategyConfig[],
}

export const daddy960_revive_initStrategies = () => {
  store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded, ...reviveIndicators]

  store.charting.initStrategy({
    configs: [...daddy960_revive_strategyGroup.main],
  })

  store.charting.changeSymbol(DEFAULT_SYMBOL)
}
