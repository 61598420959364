import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

/** MACD */
export const daddy960_revive_macd = createIndicator({
  id: 'daddy960-revive-macd',
  displayName: 'MACD',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const fastLength: number = this._input(0)
      const slowLength: number = this._input(1)
      const macdLength: number = this._input(2)

      const macd = this.macd(fastLength, slowLength, macdLength)
      const oscRed = macd.osc > 0 ? 2 * macd.osc : NaN
      const oscGreen = macd.osc < 0 ? 2 * macd.osc : NaN
      return [macd.dif, macd.macd, oscRed, oscGreen]
    },
  },

  metainfo: {
    is_price_study: false,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      { id: 'diff', type: 'line' },
      { id: 'dea', type: 'line' },
      { id: 'macdPositive', type: 'line' },
      { id: 'macdNegative', type: 'line' },
    ],

    defaults: {
      styles: {
        diff: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#f8f8f9',
        },

        dea: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ffff00',
        },
        macdPositive: {
          linestyle: 0,
          linewidth: 3,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ff1744',
        },
        macdNegative: {
          linestyle: 0,
          linewidth: 3,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#00A600',
        },
      },
      precision: 2,
      inputs: {
        fastLength: 12,
        slowLength: 26,
        macdLength: 9,
      },
    },

    styles: {
      diff: {
        title: 'DIFF',
        histogramBase: 0,
        joinPoints: false,
      },
      dea: {
        title: 'DEA',
        histogramBase: 0,
        joinPoints: false,
      },
      macdPositive: {
        title: 'MACD',
        histogramBase: 0,
        joinPoints: false,
      },
      macdNegative: {
        title: 'MACD',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    inputs: [
      {
        id: 'fastLength',
        name: 'Fast Length',
        defval: 12,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'slowLength',
        name: 'Slow Length',
        defval: 26,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },

      {
        id: 'macdLength',
        name: 'MACD Length',
        defval: 9,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
    ],
  },
})
