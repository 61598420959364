import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { calculateIndicatorValue } from './calculateIndicatorValue'

export const god_indicator15Min = createIndicator({
  id: 'god-indicator15Min',
  displayName: '指標15分K',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const SUPPORTS_RESOLUTIONS = ['1', '5', '15']
      const resolution = context.symbol.period
      if (!SUPPORTS_RESOLUTIONS.includes(resolution)) return
      const close = this.ohlc.closeArray
      close.get(100)

      const longThreshold = this._input(0)
      const shortThreshold = this._input(1)
      let biasLength = 12
      let percentRLength = 13
      let rsiLength = 6
      let stochasticLength = 9

      if (resolution === '1') {
        biasLength = biasLength * 15
        percentRLength = percentRLength * 15
        rsiLength = rsiLength * 15
        stochasticLength = stochasticLength * 15
      } else if (resolution === '5') {
        biasLength = biasLength * 3
        percentRLength = percentRLength * 3
        rsiLength = rsiLength / 5
        stochasticLength = stochasticLength * 3
      }

      const indicatorValue = calculateIndicatorValue(
        resolution as '1' | '5' | '15',
        this.bias(biasLength),
        this.percentR(percentRLength),
        this.rsi(close, rsiLength),
        this.stochastic(stochasticLength, 3, 3).k,
      )

      return [indicatorValue, longThreshold, shortThreshold]
    },
  },

  metainfo: {
    is_price_study: false,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,
    inputs: [
      {
        id: 'longThreshold',
        name: '做多基準',
        defval: 15,
        type: 'integer',
        min: 1,
        max: 100,
        step: 1,
      },
      {
        id: 'shortThreshold',
        name: '做空基準',
        defval: 85,
        type: 'integer',
        min: 1,
        max: 100,
        step: 1,
      },
    ],

    plots: [
      { id: 'indicator', type: 'line' },
      { id: 'longThreshold', type: 'line' },
      { id: 'shortThreshold', type: 'line' },
    ],
    styles: {
      indicator: {
        title: '指標數值',
        histogramBase: 0,
        joinPoints: false,
      },
      longThreshold: {
        title: '多方基準',
        histogramBase: 0,
        joinPoints: false,
      },
      shortThreshold: {
        title: '空方基準',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    defaults: {
      styles: {
        indicator: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#e6e600',
        },
        longThreshold: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#E92410',
        },
        shortThreshold: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#33cc33',
        },
      },
      precision: 2,
      inputs: {
        longThreshold: 15,
        shortThreshold: 85,
      },
    },
  },
})
