import { css } from '@emotion/react'
import { useEffectOnce, useInterval } from 'react-use'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { opkevin_initStrategies } from './opkevin_initStrategies'
import { opkevin_initStyling } from './opkevin_initStyling'
import { Opkevin_Col } from './opkevin_Col'
import { Opkevin_SidePane } from './opkevin_SidePane'
import { Opkevin_StrategyGroup } from './opkevin_StrategyGroup'
import { Daddy960_TopBar } from './component/daddy960_TopBar'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { meCreateCheckHandler } from '~/modules/SDK/me/meCreateCheckHandler'
import dayAPI from '~/utils/dayAPI'
import { fr_me } from '~/pages/heineken_template/_fr/fr_me'
import { useState } from 'react'
import { AgentProduct } from '~/configs/AgentProduct'
import { Daddy960_Footer } from './component/daddy960_Footer'
import { Daddy960LoginView } from './component/Daddy960LoginView'
import { meCheckHandlerHasMe } from '~/modules/SDK/me/meCheckHandlerHasMe'

export const opkevin_init = (templateProps: TemplateProps) => {
  useThemeStore.setState({ theme: 'dark' })
  store.charting.setThemeMode('dark')

  opkevin_initStyling(templateProps)
  opkevin_initStrategies()

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_opkevin')

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'TX-1',
    interval: 'D',
    enableVolumeIndicator: true,
    disabledHeaderWidget: false,
    disabledLeftToolbar: false,
    disabledTimeframesToolbar: false,
    overrides: store.charting.darkOverrides,
    disabledHeaderChartType: false,
    disabledHeaderCompare: true,
    disabledGoToDate: true,
    disabledHeaderSaveload: false,
  }

  templateProps.layout.cssset = css`
    grid-template-rows: 48px 36px 1fr 1fr;
    grid-template-columns: 672px 140px 1fr;
    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Col1 Drawer2 Col2'
      'Col1 Drawer2 Chart'
      'Row2 Row2 Row2';

    .Chart {
      height: calc(100vh - 84px);
    }

    ${createIPadCss(css`
      grid-template-rows: 48px 36px 1fr 1fr;
      grid-template-columns: 400px 1fr;
      grid-template-areas:
        'Row1 Row1'
        'Col1 Col2'
        'Col1 Chart'
        'Row2 Row2';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 560px 36px 560px 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Col1 Col1 Col1'
        'Col2 Col2 Col2'
        'Chart Chart Chart'
        'Row2 Row2 Row2';

      .Chart {
        height: 560px;
      }
    `)}
  `

  templateProps.layout.Row1 = <Daddy960_TopBar product='opkevin' />
  templateProps.layout.Row2 = Daddy960_Footer
  templateProps.layout.Col1 = Opkevin_Col
  templateProps.layout.Col2 = Opkevin_StrategyGroup
  templateProps.layout.Drawer2 = Opkevin_SidePane
  templateProps.layout.login = (
    <Daddy960LoginView
      product='opkevin'
      faviconUrl='opkevin/favicon.png'
      lineLink='https://liff.line.me/1645278921-kWRPP32q/?accountId=367wxcpl'
    />
  )

  templateProps.onPageEnter = () => {
    signalrStore2.addQuote(['TX-1', 'TSEA'])
  }
}
